import React, { FC, useRef, useEffect, useState } from "react";
import Section from "../../components/section";
import { PageHero } from "../../components/page-hero";
import Layout from "../../components/layout/layout";
import html_1 from "../../images/monitoringScreens/html_1.jpg";
import html_2 from "../../images/monitoringScreens/html_2.jpg";
import html_3 from "../../images/monitoringScreens/html_3.jpg";
import html_4 from "../../images/monitoringScreens/html_4.jpg";
import html_5 from "../../images/monitoringScreens/html_5.jpg";

import useCalculateHeightHook from "../../hooks/useCalculateHeightHook";
import "./styles.scss";

const HtmlMonitoring: FC = () => {
  const [size, videoLength] = useCalculateHeightHook();

  return (
    <div className="content-wrapper">
      <PageHero
        title="Source code monitoring"
        subtitle="Monitor web pages for any source code/HTML modifications."
      />
      <Layout>
        <Section>
          <div className="col-lg-12 html-monitoring">
            <div className="go-back">
              <a className="learn_btn_two" href="/how-to-use-hexowatch">
                <svg
                  width="40px"
                  height="20px"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="long-arrow-alt-left"
                  className="svg-inline--fa fa-long-arrow-alt-left fa-w-14 icon"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"
                  ></path>
                </svg>
                Back to how to use Hexowatch
              </a>
            </div>

            {size ? (
              <video
                controls
                poster={html_1}
                preload="none"
                ref={videoLength}
                style={{ height: size ? size : "auto" }}
              >
                <source
                  src={
                    "https://storage.googleapis.com/website.hexometer.com/HTML_monitor_-_Walkthrough_1598174401.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            ) : (
              <video
                controls
                poster={html_1}
                preload="none"
                ref={videoLength}
                style={{ visibility: "hidden" }}
              >
                <source
                  src={
                    "https://storage.googleapis.com/website.hexometer.com/HTML_monitor_-_Walkthrough_1598174401.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            )}

            <h2>Source code monitor - Walkthrough</h2>
            <br />
            <div className="img-block">
              <img src={html_1} alt="Select Source code monitoring tool" />
            </div>
            <p className="mb">
              The Source code monitor checks for any HTML code changes on a page. This
              is ideal to keep an eye on any web page for unauthorized changes.
              You can also look for specific fragments of HTML on a page to
              monitor your backlink partners, and ensure they link back to your
              website.
            </p>

            <div className="img-block">
              <img src={html_2} alt="Preview desired URL" />
            </div>
            <p className="mb">
              To get started enter the URL you want to check and click preview.
            </p>

            <div className="img-block">
              <img
                src={html_3}
                alt="Choose to monitor for any HTML changes, or ask Hexowatch to look for a specific snippet of HTML"
              />
            </div>
            <p className="mb">
              Once the page has loaded, you can choose to monitor for any HTML
              changes, or ask Hexowatch to look for a specific snippet of HTML.
              This type of monitor is ideal to keep an eye on your backlink
              partners to ensure they link back to you, or to get alerts when
              any code changes happen to a page.
            </p>

            <div className="img-block">
              <img
                src={html_4}
                alt="Choose the frequency of the checks, your preferred notification channels"
              />
            </div>
            <p className="mb">
              Next, you can choose the frequency of the checks, your preferred
              notification channels, as well as set custom start and end dates.
            </p>

            <div className="img-block">
              <img src={html_5} alt="Click start monitoring to get started" />
            </div>
            <p className="mb">
              Then click start monitoring to get started. Hexowatch will keep an
              eye on the page and will notify you when any HTML code changes are
              detected.
            </p>
          </div>
        </Section>
      </Layout>
    </div>
  );
};

export default HtmlMonitoring;
